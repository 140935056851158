<template>
  <div class="page-gradient">
    <section class="title container center">
      <h3>Our Services</h3>
      <p>We're ready to give you the best product in the market.</p>
    </section>
    <div class="bottom-shape">
      <img src="~@/assets/images/page-shape.png" alt="" />
    </div>
  </div>
  <div class="quality section-space">
    <div class="quality-wrapper container">
      <section class="title center">
        <h3>Exclusive Web Design & Development</h3>
        <p>Beautiful designs. Increased usability.</p>
      </section>
      <div class="quality-features flex">
        <div class="features-box flex-column-center">
          <img src="~@/assets/images/edit-tools.svg" alt="" />
          <span>Custom Web Development</span>
          <p>
            Your business website is an integral part of your business; it is
            essential to give it the attention and detail it deserves. We
            repeatedly see great website designs delivering excellent results
            for companies.
          </p>
        </div>
        <div class="features-box flex-column-center">
          <img src="~@/assets/images/price.svg" alt="" />
          <span>E-commerce Websites</span>
          <p>
            At Exclusive UX Designs, we understand that excellent web design is
            aesthetic because websites are often the first impression given to
            potential customers. We, therefore, develop great designs.
          </p>
        </div>
        <div class="features-box flex-column-center">
          <img src="~@/assets/images/responsive.svg" alt="" />
          <span>Responsive Design</span>
          <p>
            We ensure that websites we develop meet with global standards and
            tested across various devices. We, therefore, develop great designs
            that create the perfect impression in the mind of your audience.
          </p>
        </div>
      </div>
    </div>
  </div>
  <div class="technologies section-space">
    <div class="tech-shape">
      <img src="~@/assets/images/triangule-shape.png" alt="" />
    </div>
    <div class="technologies-wrapper container">
      <div class="technologies-tech flex">
        <div class="development">
          <section class="title container left">
            <h3>Top Technologies</h3>
            <p>Establishing your presence within the digital space.</p>
          </section>
          <p>
            Programming is an invaluable asset for enhancing and increasing the
            power of websites and applications. We develop websites and
            applications using in-demand programming languages such as Python,
            JavaScript, PHP, Perl, both SQL and non-SQL databases and other
            exciting technologies.
          </p>
        </div>
        <div class="tech-logos flex-wrap">
          <div class="logo-box flex-column-center">
            <img src="~@/assets/images/html5-logo.png" alt="" />
            <p>HTML 5</p>
          </div>
          <div class="logo-box flex-column-center">
            <img src="~@/assets/images/css3-logo.png" alt="" />
            <p>CSS 3</p>
          </div>
          <div class="logo-box flex-column-center">
            <img src="~@/assets/images/php-logo.png" alt="" />
            <p>PHP</p>
          </div>
          <div class="logo-box flex-column-center">
            <img src="~@/assets/images/wordpress-logo.png" alt="" />
            <p>Wordpress</p>
          </div>
          <div class="logo-box flex-column-center">
            <img src="~@/assets/images/js-logo.png" alt="" />
            <p>Javascript</p>
          </div>
          <div class="logo-box flex-column-center">
            <img src="~@/assets/images/graphql-logo.png" alt="" />
            <p>Graphql</p>
          </div>
          <div class="logo-box flex-column-center">
            <img src="~@/assets/images/vuejs-logo.png" alt="" />
            <p>Vue JS</p>
          </div>
          <div class="logo-box flex-column-center">
            <img src="~@/assets/images/nodejs-logo.png" alt="" />
            <p>Node JS</p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="reviews section-space">
    <section class="title">
      <h3>They gave us the opportunity ... We took it!</h3>
      <p>Don't just take our word for it, see what our customers are saying.</p>
    </section>
    <div class="container reviews-wrapper">
      <div class="elfsight-app-fe73e4de-eb0b-4a37-9b9d-151035dbdb4b"></div>
    </div>
  </div>
</template>
<style scoped>
@import '../assets/css/services.css';
</style>
